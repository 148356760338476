<template>
  <div
    class="hidden md:grid grid-cols-2 w-full font-semibold mb-4"
    v-if="sources.length === 2"
  >
    <div
      class="text-center"
      v-for="(source, index) in sources"
      :key="index"
    >
      {{ source }}
    </div>
  </div>

  <ul class="border border-gray-200 rounded-md divide-y divide-gray-200 max-h-80 overflow-y-auto">
    <li
      v-for="page in pagesList"
      :key="page.id"
      class="px-4 py-4 text-sm hover:bg-gray-200 flex gap-4 items-center"
    >
      <input type="radio" name="page" :id="page.id" :value="page.id" v-model="selectedPageId">
      <div class="grid gap-2 grid-cols-1 md:grid-cols-2 w-full">
        <div class="flex gap-2 justify-start items-center">
          <div class="flex-none w-14 relative">
            <img :src="page.picture" class="w-12 h-12 rounded-full" @error="imageFallback" />
            <div
              class="select-button absolute right-0 bottom-0"
              :style="getSocialButton(page.source)?.style || {}"
            >
              <i
                class="fab text-white"
                :class="getSocialButton(page.source)?.class || {}"
              >
              </i>
            </div>
          </div>
          <div class="font-semibold">
            {{ page.name }}
          </div>
        </div>
        <div v-if="sources.length === 2" class="flex gap-2 justify-start items-center">
          <div class="flex-none w-14 relative">
            <img :src="page.connectedAccount?.picture" class="w-12 h-12 rounded-full" @error="imageFallback" />
            <div
              class="select-button absolute right-0 bottom-0"
              :style="getSocialButton(page.connectedAccount?.source)?.style || {}"
            >
              <i
                class="fab text-white"
                :class="getSocialButton(page.connectedAccount?.source)?.class || {}"
              >
              </i>
            </div>
          </div>


          <div>
            <div class="font-semibold">
              {{ page.connectedAccount?.name }}
            </div>
            {{ page.connectedAccount?.username }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts';
import { computed, defineComponent } from 'vue'

interface PageProps {
  id: string;
  name: string;
  picture: string;
  source: string;
  link?: string;
  icon?: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    pagesList: {
      type: Array,
      required: true
    },
    sources: {
      type: Array,
      required: true
    }
  },

  emits : [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    const getSocialButton = (source: string) => {
      return socialButtons.value.find(button => button.source === source)
    }

    const selectedPageId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    return {
      selectedPageId,
      getSocialButton,
      imageFallback
    }
  }
})
</script>

<style>
.select-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.select-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
</style>